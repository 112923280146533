import React from "react";
import { graphql } from "gatsby";
import SEO from "../components/SEO";
import { PostList } from "../components/Blog/PostList";
import { decodeEntities } from "../utils/helpers";
import { Header } from "../components/Acf/Header";

const Category = (props) => {
  const { data, pageContext, location } = props;
  const {
    postsOnPage,
    categoryFilter,
    categories,
    site,
    wordpressWpSettings,
    yoast = [],
    siteSettings,
    newsHeader,
  } = data;
  const { wordpressUrl } = wordpressWpSettings;
  const { showAuthor, customCss } = siteSettings.options;
  const { edges: posts, totalCount } = postsOnPage;
  const { title: siteTitle, date_format } = wordpressWpSettings;
  const { name: category, slug, pathPrefix } = pageContext;
  const title = `${category} Category`;

  return (
    <>
      <SEO
        title={`${decodeEntities(category)} | ${decodeEntities(siteTitle)}`}
        yoast={yoast}
        location={location}
      />
      <Header {...newsHeader.childWordPressAcfHeader} />
      <PostList
        posts={posts}
        title={title}
        pageContext={pageContext}
        categoryFilter={categoryFilter}
        siteMetadata={wordpressWpSettings}
        pathPrefix={`/news/${slug}/`}
        showAuthor={showAuthor}
        dateFormat={date_format}
      />
    </>
  );
};

export default Category;

export const pageQuery = graphql`
  query CategoryPage($slug: String!, $limit: Int!, $skip: Int!) {
    wordpressWpSettings {
      title
      blogSlug
      wordpressUrl
      siteUrl
      date_format
    }
    siteSettings: wordpressAcfOptions {
      options {
        showAuthor
        customCss
      }
    }
    categoryFilter: allWordpressPost {
      nodes {
        categories {
          wordpress_id
          id
          name
          slug
          path
          id
        }
      }
    }
    postsOnPage: allWordpressPost(
      filter: {
        categories: { elemMatch: { slug: { eq: $slug } } }
        slug: { ne: "dummy-post" }
      }
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...PostListFields
        }
      }
    }
    categories: allWordpressCategory(filter: { count: { gt: 0 } }) {
      edges {
        node {
          name
          slug
          featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 670, quality: 70) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          count
        }
      }
    }
    newsHeader: wordpressPage(slug: { eq: "news" }) {
      childWordPressAcfHeader {
        title
        content
        carousel {
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1150, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        homepage
        theme
      }
    }
  }
`;
